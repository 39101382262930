<template>
  <div>
    <dashboard-page-title :showFilter="true" :showSearch="true" :showExportation="true" @addEvent="$router.push({name: 'addVehicleMake'})">انواع الشاحنات</dashboard-page-title>

    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :status-url="'vehicle-makes/status'" :list_url="'vehicle-makes'"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
export default {
  name: 'vehiclesMake',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_TRANSPORTATION_LINK,
      fields: [
        { label: 'شعار المركبة', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'اسم المركبة', key: 'name', type: 'name', class: 'text-right' }
        // {
        //   label: 'الحالة', key: 'change_status'
        // }
        // {
        //   label: 'التحكم',
        //   key: 'actions',
        //   class: 'text-right',
        //   type: 'actions',
        //   actions: [
        //     {
        //       text: 'view',
        //       icon: 'ri-eye-fill',
        //       color: 'primary',
        //       ifNavigate: true,
        //       routePath: 'view-vehicle-type/:id'
        //     },
        //     {
        //       text: 'edit',
        //       icon: 'ri-ball-pen-fill',
        //       color: 'info',
        //       ifNavigate: true,
        //       routePath: 'edit-vehicle-type/:id'
        //     },
        //     {
        //       text: 'Delete',
        //       icon: 'ri-delete-bin-line',
        //       color: 'danger',
        //       url: 'allowed-vehicle-types',
        //       baseUrl: VUE_APP_TRANSPORTATION_LINK,
        //       titleHeader: 'منتج',
        //       question: 'هل متأكد أنك تريد مسح هذا المنتج',
        //       textContent: 'name',
        //       textDeleteButton: 'بالتأكيد, مسح',
        //       textCancelButton: 'الغاء',
        //       showAlert: true
        //     }
        //   ]
        // }
      ]
    }
  }
}
</script>
